<template>
  <v-container
    fluid
    class="led-list"
  >
    <!-- This is the table of LEDs -->
    <v-data-table
      v-if="haveAccess"
      :headers="tableHeaders"
      :items="tableItems"
      :loading="loading"
      item-key="id"
      :items-per-page-items="rowsPerPageOptions"
      class="elevation-1"
      @click:row="routeToDetailPage"
    >
      <template #[`item.date`]="{ item }">
        <td>{{ item.date.format('[On] ll [at] LT') }}</td>
      </template>

      <template
        #[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        LEDs {{ pageStart }} to {{ pageStop }} out of {{ itemsLength }}
      </template>
    </v-data-table>

    <!-- This is the add new LED floating action button -->
    <v-btn
      v-show="haveAccess"
      dark
      fab
      bottom
      right
      fixed
      class="fab-bottom-right"
      color="accent"
      @click.stop="openAddLedForm"
    >
      <v-icon>mdi-plus</v-icon>
      <span class="fab-text">
        Add new LED
      </span>
    </v-btn>

    <!-- This is the slide-up fullscreen dialog to add a new LED -->
    <v-dialog
      v-model="addLedDialog"
      scrollable
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card
        tile
        class="add-led"
      >
        <!-- This is the toolbar at the top of the add LED popup -->
        <v-app-bar
          flat
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click.native.stop="closeAddLedForm"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add New LED</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click.stop="clearFields"
            >
              <span>Clear</span>
            </v-btn>
            <v-btn
              dark
              text
              :loading="loading"
              @click.native="refreshForm"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-app-bar>

        <!-- Here is the actual content of the add LED popup -->
        <v-card-text>
          <!-- This is now the AddLED form thingo -->
          <v-container
            id="AddLEDForm"
            fluid
          >
            <v-row>
              <v-col
                md="6"
                offset-md="3"
                sm="8"
                offset-sm="2"
                cols="12"
              >
                <v-form
                  ref="newLedForm"
                  v-model="formValid"
                  lazy-validation
                >
                  <!-- This is the for loop to generate the fields -->
                  <FormField
                    v-for="entry in entryFields"
                    :key="entry.key"
                    :entry="entry"
                    @onDynamicUpdate="refreshForm"
                  />
                  <!-- This is the button for selecting who to submit as -->
                  <UserSelectSubmitter
                    :form-valid="formValid"
                    :loading="loading"
                    :show-clear="false"
                    @onSubmitClick="postNewLed"
                    @onClearClick="clearFields"
                  />
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- This is the fullscreen LED detail page dialog -->
    <v-dialog
      v-if="ledDetailOpen"
      v-model="ledDetailOpen"
      scrollable
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card
        tile
        class="led-detail-dialog"
      >
        <!-- Here is the LED detail popup toolbar -->
        <v-app-bar
          flat
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            :to="{ name: 'led_list' }"
            replace
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              dark
              text
              :loading="loading"
              @click.native="$refs.led_detail_dialog.refreshInfo"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-app-bar>

        <!-- Here is the content of the LED detail popup -->
        <v-card-text class="fill-height">
          <LEDDetail ref="led_detail_dialog" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from '../scripts/event_bus'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    FormField: () => import('../components/FormField'),
    UserSelectSubmitter: () => import('../components/UserSelectSubmitter'),
    LEDDetail: () => import('../components/LEDDetail')
  },
  data () {
    return {
      tableHeaders: [
        { text: 'Serial Number', value: 'internal_serialnumber', align: 'left' },
        { text: 'Production Lot', value: 'lot' },
        { text: 'Date Assembled', value: 'date' },
        { text: 'Manufacturer', value: 'manufacturer' },
        { text: 'Number of Notes', value: 'numNotes' },
        { text: 'Added by', value: 'username' }
      ],
      rowsPerPageOptions: [
        10, 20, 50,
        { text: 'All', value: -1 }
      ],
      fabOpen: false,
      addLedDialog: false,
      diodeID: 1,
      formValid: false,
      detailDialog: false,
      entryFields: []
    }
  },
  computed: {
    ...mapGetters({
      userData: 'userData',
      tableItems: 'ledList',
      loading: 'loading',
      haveAccess: 'haveAccess'
    }),
    ledDetailOpen: function () {
      return this.$route.name === 'led_detail'
    }
  },
  mounted () {
    // Subscribe to the global refresh event
    EventBus.$on('refresh', this.refreshList)
    // Refresh the list itself on component load
    this.refreshList()
  },
  beforeDestroy () {
    EventBus.$off('refresh', this.refreshList)
  },
  methods: {
    ...mapActions({
      refreshList: 'refreshLEDList',
      updateFields: 'updateNewLEDFields'
    }),
    openAddLedForm: function () {
      // Make double sure the user is allowed to do this
      if (this.haveAccess) {
        // Refresh the form from the server (this is an async process)
        this.refreshForm()
        // Open the dialog (which will open right away, even if it is still loading the form)
        this.addLedDialog = true
      }
    },
    closeAddLedForm: function () {
      // Close the form
      this.addLedDialog = false
      // Reset all the fields, they will be refreshed again on open
      this.$refs.newLedForm.reset()
    },
    refreshForm: function () {
      if (!this.loading) {
        this.entryFields.push({ key: 'action', value: 'refresh' })

        this.updateFields({
          fields: this.entryFields,
          responseHandler: responseData => {
            this.entryFields = responseData.fields
          }
        })
      }
    },
    postNewLed: function (chosenUser) {
      if (!this.loading) {
        console.log('Posting new LED.')
        // this.loading = true

        if (this.$refs.newLedForm.validate()) {
          // If the chosen user is valid (in testing sometimes it isn't), add it
          if (chosenUser != null) {
            this.entryFields.push({ key: 'chosen_user', value: chosenUser.id })
          }
          // Add the action depending on what was asked for
          this.entryFields.push({ key: 'action', value: 'save' })

          this.updateFields({
            fields: this.entryFields,
            responseHandler: responseData => {
              // Check to see if the server added the LED to the database
              if (responseData.action === 'saved') {
                // If it did, let the user know (simple for now)
                EventBus.$emit('info', 'Successfully added new LED')
                // Then close the form
                this.addLedDialog = false
                // And reset the fields, they will be refreshed on opening the form again
                this.clearFields()
                // Refresh the list of LEDs after a short delay
                setTimeout(() => {
                  this.refreshList()
                }, 200)
              } else {
                // If not, refresh the form with new data
                this.entryFields = responseData.fields
                // Then send an error event with the reason for failure
                EventBus.$emit('error', {
                  title: 'LED not added',
                  message: responseData.error_message != null ? responseData.error_message : 'Unknown error'
                })
              }
            }
          })
        } else {
          console.log('Form invalid, will not post for save')
          // this.loading = false
        }
      } else {
        console.log('Still loading, please wait')
      }
    },
    clearFields: function () {
      this.$refs.newLedForm.reset()
      // Refresh the form on a timeout
      setTimeout(this.refreshForm, 200)
    },
    routeToDetailPage: function (led) {
      this.$router.push({ name: 'led_detail', params: { led_id: led.id } })
      if (this.$refs.led_detail_dialog) {
        this.$refs.led_detail_dialog.reset()
      }
    }
  }
}
</script>
